'use client'
// import tech from '@/app/(pages)/service/data/tech.json';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Socialmedia from './Sociallmedia';

interface TechItem {
  title: string;
  description: string;
  image: string;
  link: string;
}

export default function Header2() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);


  const toggle1 = () => { setIsOpen1(prevState => !prevState); };
  const toggle2 = () => { setIsOpen2(prevState => !prevState); };
  const toggle3 = () => { setIsOpen3(prevState => !prevState); };
  const toggle4 = () => { setIsOpen4(prevState => !prevState); };
  const toggle5 = () => { setIsOpen5(prevState => !prevState); };


  // const techs = Object.values(tech);

  const solutionsData = {
    'inventory': {
      title: "Inventory & Warehouse",
      description: "Manage your inventory and warehouse operations efficiently.",
      image: "/assets/images/solution/jbcodeapp_solution_marketing_automation.webp",
      link: "/solutions/"
    },
    'financial': {
      title: "Financial Accounting",
      description: "Streamline your financial accounting processes with our solution.",
      image: "/assets/images/solution/jbcodeapp_solution_human_resources_management_system.webp",
      link: "/solutions/"
    },
    'vehicle': {
      title: "Vehicle Tracking System",
      description: "Track and manage your fleet effectively.",
      image: "/assets/images/solution/jbcodeapp_solution_marketing_automation.webp",
      link: "/solutions/"
    },
    'transport': {
      title: "Transport Management Solutions",
      description: "Optimize your transport operations with advanced solutions.",
      image: "/assets/images/solution/jbcodeapp_solution_transport_management_system.webp",
      link: "/solutions/"
    },
    'hrms': {
      title: "HR Management System (HRMS)",
      description: "Manage your human resources efficiently with our HRMS.",
      image: "/assets/images/solution/jbcodeapp_solution_human_resources_management_system.webp",
      link: "/solutions/"
    },
    'smart-station': {
      title: "Smart Station Management",
      description: "Enhance your station management with smart solutions.",
      image: "/assets/images/solution/jbcodeapp_solution_marketing_automation.webp",
      link: "/solutions/"
    },
    'customer-relation': {
      title: 'Customer Relationship Management (CRM)',
      description: 'Manage and improve interactions with customers.',
      image: '/assets/images/solution/jbcodeapp_solution_ai_assistance.webp',
      link: '/solutions/'
    },
    'smart-estate': {
      title: 'Smart Estate Management',
      description: 'Efficiently manage real estate and property.',
      image: '/assets/images/solution/jbcodeapp_solution_transport_management_system.webp',
      link: '/solutions/'
    },
    'procurement': {
      title: 'Procurement Management System',
      description: 'Streamline and control purchasing processes.',
      image: '/assets/images/solution/jbcodeapp_solution_transport_management_system.webp',
      link: '/solutions/'
    },
    'real-estate': {
      title: 'Real Estate & Construction',
      description: 'Solutions for real estate and construction industries.',
      image: '/assets/images/solution/jbcodeapp_solution_marketing_automation.webp',
      link: '/solutions/'
    },
    'sales-invoice': {
      title: 'Sales & Invoice Processing',
      description: 'Handle sales and manage invoices easily.',
      image: '/assets/images/solution/jbcodeapp_solution_human_resources_management_system.webp',
      link: '/solutions/'
    },
    'visitor-management': {
      title: 'Visitor Management System',
      description: 'Track and manage visitors at your premises.',
      image: '/assets/images/solution/jbcodeapp_solution_visitor_management.webp',
      link: '/solutions/'
    }

  };

  const [selectedSolution, setSelectedSolution] = useState<TechItem | null>(null);

  const handleMouseOver = (key) => {
    setSelectedSolution(solutionsData[key]);
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 390 && window.innerHeight <= 844);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* Header area start */}
      <header className="header__area-2">
        <div className="header__inner-2">
          <div className="header__logo-2">
            <a href="/" aria-label='Home'>
              {/* Logo */}
              {/* <img src="/assets/imgs/logo/site-logo-white-2.png" alt="Site Logo" /> */}
              <img
                // src="/assets/jbcodeapp_logo_white_no_space.webp"
                src="/assets/jbcodeapp_logo_white_logo.png"
                alt="Jbcodeapp Logo"
                className='h-auto w-auto'
              />
            </a>
          </div>
          <div className="header__nav-2">
            <ul className="main-menu menu-anim " style={{ textAlign: "center" }} >
              {/* Pages */}

              {/* HIRE DEVELOPERS */}
              <li className="has-megamenu">
                <a href="/services/hire-dedicated-developers/" aria-label='HIRE DEVELOPERS'>HIRE DEVELOPERS</a>
                <ul className="mega-menu-2">
                  {/* Hire Website Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Website Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className="p-0 m-0 list-none">
                      <li className="whitespace-nowrap">
                        <a href="/services/web/hire-php-web-developers/" className="block" aria-label='Hire PHP Developers'>Hire PHP Developers</a>
                      </li>
                      <li className="whitespace-nowrap">
                        <a href="/services/web/hire-laravel-web-developers/" className="block" aria-label='Hire Laravel Developers'>Hire Laravel Developers</a>
                      </li>
                      <li className="whitespace-nowrap">
                        <a href="/services/web/hire-nodejs-developers/" className="block" aria-label='Hire NodeJS Developers'>Hire NodeJS Developers</a>
                      </li>
                      <li className="whitespace-nowrap">
                        <a href="#" className="block" aria-label='Hire ExpressJS Developers'>Hire ExpressJS Developers</a>
                      </li>
                    </ul>
                  </li>

                  {/* Hire Mobile Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Mobile Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/services/mobile/hire-ios-developers/" aria-label='Hire iOS Developers'>Hire iOS Developers</a>
                      </li>
                      <li>
                        <a href="/services/mobile/hire-android-app-developers/" aria-label='Hire Android Developers'>Hire Android Developers</a>
                      </li>
                      <li>
                        <a href="#" aria-label='Hire Flutter Developers'>Hire Flutter Developers</a>
                      </li>
                      <li>
                        <a href="#" aria-label='Hire Swift Developers'>Hire Swift Developers</a>
                      </li>
                    </ul>
                  </li>

                  {/* Hire Frontend Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Frontend Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/services/web/hire-reactjs-developers/" className="whitespace-nowrap" aria-label='Hire ReactJS Developers'>Hire ReactJS Developers</a>
                      </li>
                      <li>
                        <a href="#" className="whitespace-nowrap">Hire Angular Developers</a>
                      </li>
                      <li>
                        <a href="#" className="whitespace-nowrap" aria-label='Hire NextJS Developers'>Hire NextJS Developers</a>
                      </li>
                      <li>
                        <a href="#" className="whitespace-nowrap" aria-label='Hire VueJS Developers'>Hire VueJS Developers</a>
                      </li>
                    </ul>
                  </li>

                  {/* Hire Open Source Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Open Source Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className="!min-w-full">
                      <li>
                        <a href="/services/web/hire-wordpress-developers/" aria-label='Hire Wordpress Developers'>Hire Wordpress Developers</a>
                      </li>
                      <li>
                        <a href="/services/web/hire-shopify-developers/" aria-label='Hire Shopify Developers'>Hire Shopify Developers</a>
                      </li>
                      <li>
                        <a href="/services/web/hire-magento-developers/" aria-label='Hire Magento Developers'>Hire Magento Developers</a>
                      </li>
                      <li>
                        <a href="/services/web/hire-drupal-web-developer/" aria-label='Hire Drupal Developers'>Hire Drupal Developers</a>
                      </li>
                    </ul>

                    {/* Contact Us */}
                    {/* <div className="flex flex-col border-t border-gray-300 items-center justify-center text-center">
                      <p className="text-md pt-2 pb-2">Start with experts</p>
                      <a
                        className="px-6 py-2 rounded-md bg-white hover:bg-[#c9f31d] border border-gray-500 text-gray-600 font-medium transition-colors duration-300"
                        href="contact"
                      >
                        Contact Us
                      </a>
                    </div> */}
                  </li>
                </ul>
              </li>


              {/* Services */}
              <li className="has-megamenu ">
                <a href="/what-we-do/" aria-label='Services'>
                  SERVICES
                </a>
                <ul className="mega-menu-2  ">
                  {/* what we do/services */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/what-we-do/" className="text-b relative z-10 hover:text-[#c9f31d]" aria-label='What We Do'>
                          What We Do
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <ul>
                      <li>
                        <a href="/what-we-do/web-development/" aria-label='Web Development'>Web Development</a>
                      </li>
                      <li>
                        <a href="/what-we-do/mobile-app-development/" aria-label='Mobile App Development'>Mobile App Development</a>
                      </li>
                      <li>
                        <a href="/what-we-do/design/" aria-label='UI/UX Designs'>UI/UX Designs</a>
                      </li>
                      <li>
                        <a href="/what-we-do/ai-ml-solutions/" aria-label='AI & ML App Development'>
                          AI & ML App Development
                        </a>
                      </li>
                      <li>
                        <a href="/services/digital-marketing-services/" aria-label='Digital Marketing'>
                          Digital Marketing
                        </a>
                      </li>
                      <li>
                        <a href="/services/hire-dedicated-developers/" aria-label='Hire Dedicated Developers'>
                          Hire Dedicated
                          Developers
                        </a>
                      </li>
                      <li>
                        <a href="/what-we-do/frontend-development/" aria-label='Frontend development'>Frontend development</a>
                      </li>
                      <li>
                        <a href="/what-we-do/backend-development/" aria-label='Backend development'>Backend development</a>
                      </li>
                      <li>
                        <a href="/what-we-do/fullstack-development/" aria-label='fullstack development'>fullstack development</a>
                      </li>
                    </ul>

                  </li>
                  {/* Industries */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/industries/" className="text-b relative z-10 hover:text-[#c9f31d]" aria-label='Industries'>
                          Industries
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/industries/education-e-learning-it-solutions/" aria-label='Education / e-learning'>Education / e-learning</a>
                      </li>
                      <li>
                        <a href="/industries/healthcare-it-solutions/" aria-label='Healthcare'>Healthcare</a>
                      </li>
                      <li>
                        <a href="/industries/logistics-and-distribution-it-solutions/" aria-label='Logistics and Distribution'>Logistics & Distribution</a>
                      </li>
                      <li>
                        <a href="/industries/travel-and-hospitality-it-solutions/" aria-label='Travel and Hospitality'>Travel & Hospitality</a>
                      </li>
                      <li>
                        <a href="/industries/retail-it-solutions/" aria-label='Retail and eCommerce'>Retail & eCommerce</a>
                      </li>
                      <li>
                        <a href="/industries/finance-and-insurance/" aria-label='Finance and Insurance'>Finance & Insurance</a>
                      </li>
                      <li>
                        <a href="/industries/it-and-telecom/" aria-label='IT and Telecom'>IT & Telecom</a>
                      </li>
                      <li>
                        <a href="/industries/media-and-entertainment/" aria-label='Media and Entertainment'>Media & Entertainment</a>
                      </li>
                      <li>
                        <a href="/industries/social-media/" aria-label='Social Media'>Social Media</a>
                      </li>
                    </ul>
                  </li>

                  {/* Focus */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/focus/" className="text-b relative z-10 hover:text-[#c9f31d]" aria-label='Focus'>
                          Focus
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className='!min-w-full'>
                      <li>
                        <a href="/">AI Solutions</a>
                      </li>
                      <li>
                        <a href="/">Enterprise Software </a>
                      </li>
                      <li>
                        <a href="/">Tech Integration Services</a>
                      </li>
                      <li>
                        <a href="/">Automated Business </a>
                      </li>
                      <li>
                        <a href="/">E-Commerce Solutions</a>
                      </li>
                      <li>
                        <a href="/">Business Analytics</a>
                      </li>
                    </ul>

                  </li>

                  {/* Design */}
                  <li className=''>
                    <div className='bg-gradient-to-b from-zinc-600 via-slate-50 to-zinc-400 rounded-md px-3 py-2' >

                      <img src="/assets/images/jbcodeapp_methodology.webp"
                        className='pt-4 pb-3 borderpx-2 py-2'
                        alt="jbcodeapp solution"
                        width=""
                        height=""
                      />

                      <div className="menu-heading text-black text-center">
                        {/* <a href="/"> */}
                        Smart Solutions
                        {/* </a> */}

                      </div>
                    </div>
                  </li>

                </ul>
                <ul>

                </ul>
              </li>

              {/* Solution */}
              <li className="has-megamenu">
                <a href="/solutions/" aria-label='Solutions'>
                  SOLUTIONS
                </a>
                <ul className="mega-menu-2">

                  {/* Solution */}
                  <li className='!min-w-min'>
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        {/* <Link href="/solutions/" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        Customise Solutions
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <ul>
                      <li onMouseOver={() => handleMouseOver('inventory')}>
                        <a href="/solutions/" aria-label='Inventory & Warehouse'>Inventory & Warehouse</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('financial')}>
                        <a href="/solutions/" aria-label='Financial Accounting'>Financial Accounting</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('vehicle')}>
                        <a href="/solutions/" aria-label='Vehicle Tracking System'>Vehicle Tracking System</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('transport')}>
                        <a href="/solutions/" aria-label='Transport Management Solutions'>Transport Management Solutions</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('hrms')}>
                        <a href="/solutions/" aria-label='HR Management System (HRMS)'>HR Management System (HRMS)</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('smart-station')}>
                        <a href="/solutions/" aria-label='Smart Station Management'>Smart Station Management</a>
                      </li>
                    </ul>
                  </li>

                  {/* 2nd Line */}
                  <li className='!min-w-min'>
                    <div className="menu-heading text-black">
                      <div className="relative group inline-block">
                        {/* <Link href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        {/* No content needed here */}
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    {/* <ul>
                      <li><a href="/industries/legal">Customer Relationship Management (CRM)</a></li>
                      <li><a href="/industries/logistics">Smart Estate Management</a></li>
                      <li><a href="/industries/finance">Procurement Management System</a></li>
                      <li><a href="/industries/real-estate">Real Estate & Construction</a></li>
                      <li><a href="/industries/education">Sales & Invoice Processing</a></li>
                      <li><a href="/industries/healthcare">Visitor Management System</a></li>
                    </ul> */}
                    <ul>
                      <li onMouseOver={() => handleMouseOver('customer-relation')}>
                        <a href="/solutions/" aria-label='Customer Relationship Management (CRM)'>Customer Relationship Management (CRM)</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('smart-estate')}>
                        <a href="/solutions/" aria-label='Smart Estate Management'>Smart Estate Management</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('procurement')}>
                        <a href="/solutions/" aria-label='Procurement Management System'>Procurement Management System</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('real-estate')}>
                        <a href="/solutions/" aria-label='Real Estate & Construction'>Real Estate & Construction</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('sales-invoice')}>
                        <a href="/solutions/" aria-label='Sales & Invoice Processing'>Sales & Invoice Processing</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('visitor-management')}>
                        <a href="/solutions/" aria-label='Visitor Management'>Visitor Management </a>
                      </li>
                    </ul>

                  </li>

                  <li className='!w-0'>
                    {/* <div className="absolute top-0 bottom-0 left-0 w-px bg-neutral-500" /> */}
                  </li>


                  {/* Featured Solution */}
                  <li className='!min-w-[350px] !ml-[-30%] flex flex-col '>
                    {/* <div className='bg-gradient-to-b from-zinc-600 via-slate-50 to-zinc-400 rounded-md px-3 py-2 w-[-50%]'> */}
                    <img src={selectedSolution ? selectedSolution.image : '/assets/images/solution/jbcodeapp_solution_transport_management_system.webp'}
                      className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} />

                    <div className="menu-heading text-black ">
                      {/* <a href={selectedSolution ? selectedSolution.link : "/"}> */}
                      {selectedSolution ? selectedSolution.title : 'Smart Solutions'}
                      {/* </a> */}
                    </div>
                    <p className="sec-text text-black pb-3 !min-w-fit">
                      {selectedSolution ? selectedSolution.description : 'Clarify Project Goals for Accurate Costs and Timely Completion'}
                    </p>

                  </li>

                </ul>
              </li>

              {/* Our Work */}
              <li>
                <a href="/portfolio/" aria-label='Our Work'>OUR WORK</a>

                <ul className="mega-menu-2 ">
                  {/* Case Studies */}
                  <li className='!min-w-full'>
                    <div  >
                      <div className="menu-heading text-black border-b ">
                        <div className="relative group inline-block">
                          Case Studies
                          <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                      </div>

                      <a href="/case-studies/" className="text-b relative z-10 hover:text-[#c9f31d]" aria-label='Case Studies'>
                        <img
                          src="/assets/images/jbcodeapp_case_studies.webp"
                          className=' pb-1 hover:bg-neutral-300 mt-3 rounded-lg border border-black hover:border-black'
                          alt="jbcodeapp case studies" />
                      </a>

                    </div>
                  </li>


                  {/* Portfolio */}
                  <li className='!min-w-full'>
                    <div>
                      <div className="menu-heading text-black border-b ">
                        <div className="relative group inline-block">
                          Portfolio
                          <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                      </div>

                      <a href="/portfolio/" className="text-b relative z-10 hover:text-[#c9f31d]" aria-label='our work'>
                        <img
                          src="/assets/images/jbcodeapp_our_work.svg"
                          className=' pb-1 hover:bg-neutral-300 mt-3 rounded-lg border border-black hover:border-black'
                          alt="jbcodeapp our work Image"
                        />
                      </a>

                    </div>
                  </li>

                  {/* Products */}
                  <li className='!min-w-full'>
                    <div  >
                      <div className="menu-heading text-black border-b ">
                        <div className="relative group inline-block">
                          Products
                          <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                      </div>

                      <a href="/" className="text-b  z-10 hover:text-[#c9f31d]" aria-label='product'>
                        <img
                          src="/assets/images/jbcodeapp_product.webp"
                          className='pb-1 hover:bg-neutral-300 mt-3 rounded-lg border border-black hover:border-black'
                          alt="jbcodeapp product Image" />
                      </a>

                    </div>
                  </li>

                  {/* Testimonials */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        Client Testimonials
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <div className="testimonial__info-4 pt-5">
                      <div className='  px-2 py-2 rounded-lg hover:bg-neutral-300'>

                        <p className=''>
                          We were there from the start of SEO's rise and have since grown to a team of over 50 talented specialists with diverse skills and extensive experience.
                        </p>
                        <img
                          className=" !h-10 !w-10 "
                          src="/assets/images/jbcodeapp_testimonials_foreign_man.webp"
                          alt="Testimonial Image"
                        />
                        <h5 className="testimonial__name-4 py-2"> Cameron Delacruz </h5>
                        <h6 className="testimonial__role-4 "> Florida, United State </h6>
                      </div>

                    </div>
                  </li>

                </ul>

              </li>

              {/* Company */}
              <li className="has-megamenu ">
                <a href="/" aria-label='Company'>
                  COMPANY
                </a>
                <ul className="mega-menu-2 ">
                  {/* what we do */}
                  <li>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        {/* <Link href="/what-we-do" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        Overview
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <ul className='!min-w-full'>
                      <li>
                        <a href="/about/" aria-label='About Us'>About Us</a>
                      </li>
                      <li>
                        <a href="/how-to-engage/" aria-label='How We Work'> How We Work </a>
                      </li>
                      <li>
                        <a href="/career/" aria-label='Career'>Career</a>
                      </li>
                      <li>
                        <a href="/methodologies/" aria-label='Methodology'>  Methodology  </a>
                      </li>
                    </ul>

                  </li>

                  {/* Insights */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        {/* <Link href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        Insights
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/faqs/" aria-label='faqs'>faqs</a>
                      </li>
                      <li>
                        <a href="/blog/" aria-label='Blogs'>Blogs</a>
                      </li>
                      <li>
                        <a href="/" aria-label='Testimonials'>Testimonials</a>
                      </li>
                    </ul>

                  </li>

                  {/* design */}
                  <li className='!min-w-full'>
                    <div className='bg-gradient-to-b from-zinc-400 via-slate-50 to-zinc-600 rounded-md px-3 py-2 text-center' >

                      <img
                        src="/assets/images/jbcodeapp_company_about.webp"
                        className='pt-4 pb-3 px-2 py-2 h-40 mx-auto'
                        alt="jbcodeapp company about Image" />

                      <div className="menu-heading text-white">
                        <a href="/about/" aria-label='About Us'>
                          About Us
                        </a>

                      </div>

                    </div>
                  </li>

                  <li className='!min-w-full'>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/googlerating.webp"
                          className="h-full w-full"
                          alt="Google Rating"
                        /> */}
                        <img src="/assets/images/icon/g1.png" className='px-1 py-2 h-15' alt="Google Rating" loading="lazy" />

                      </div>
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/upwork-rating.png"
                          className="h-full w-full"
                          alt="Upwork Rating"
                        /> */}
                        <a href="https://www.upwork.com/agencies/jbcodeapp/" target="_blank" rel="noopener noreferrer" aria-label='Visit JBCodeApp on Upwork'>
                          <img src="/assets/images/icon/up1.png" className='px-1 py-2 h-15' alt="Upwork Rating" loading="lazy" />
                        </a>
                      </div>
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/clutch-rating.png"
                          className="h-full w-full"
                          alt="Clutch Rating"
                        /> */}
                        <a href="https://clutch.co/profile/jbcodeapp" target="_blank" rel="noopener noreferrer" aria-label='Visit JBCodeApp on Clutch'>
                          <img src="/assets/images/icon/c1.webp" className='px-1 py-2 h-15' alt="Clutch Rating" loading="lazy" />
                        </a>


                      </div>
                      <div className=" flex flex-col items-center justify-center p-2">
                        <a href="https://www.goodfirms.co/company/jbcodeapp" target="_blank" rel="noopener noreferrer" aria-label='Visit JBCodeApp on Goodfirms'>
                          <img src="/assets/images/icon/Goodfirms.webp" className='px-1 py-2 h-15' alt="Goodfirms Rating" loading="lazy" />
                        </a>
                        {/* <img
                          src="/assets/images/icon/clutch-rating.png"
                          className="h-full w-full"
                          alt="Clutch Rating"
                        /> */}
                      </div>
                    </div>
                  </li>

                </ul>
              </li>

              {/* Let's Talk */}
              <li >
                <div className="footer__contact-3" style={{ paddingTop: "0px" }}>
                  <a className="end" style={{ fontSize: "22px" }} href="/contact/" aria-label='Let’s talk'>
                    Let’s talk
                  </a>
                </div>
              </li>

            </ul>
          </div>

          <div className="header__nav-icon-2">
            <button className="menu-icon-2" id="open_offcanvas">
              {/* <img src="/assets/imgs/icon/menu-white.png" alt="Menubar Icon" /> */}
              <i className="fa-solid fa-bars text-white text-2xl"></i>
            </button>
          </div>

        </div>
      </header>
      {/* Header area end */}

      {/* Offcanvas area start */}
      <div className="offcanvas__area">
        <div className="offcanvas__body">
          <div className="offcanvas__left">

            <div className="offcanvas__logo">
              <a href="index">
                <img
                  src="/assets/jbcodeapp_logo_white_logo.png"
                  alt="Offcanvas Logo"
                />
              </a>
            </div>

            <div className="offcanvas__social">
              <h3 className="social-title">Follow Us</h3>
              <ul>
                <li>
                  <a href="https://www.dribbble.com/jbcodeapp" aria-label='Visit JBCodeApp on Dribbble'>Dribbble</a>
                </li>
                <li>
                  <a href="https://www.behance.net/jbcodeapp" aria-label='Visit JBCodeApp on Behance'>Behance</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/jbcodeapp" aria-label='Visit JBCodeApp on Instagram'>Instagram</a>
                </li>
                <li>
                  <a href="https://www.facebook.com/jbcodeapp" aria-label='Visit JBCodeApp on Facebook'>Facebook</a>
                </li>
                <li>
                  <a href="https://twitter.com/jbcodeapp" aria-label='Visit JBCodeApp on Twitter'>Twitter</a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@jbcodeapp" aria-label='Visit JBCodeApp on YouTube'>YouTube</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/jbcodeapp/" aria-label='Visit JBCodeApp on Linkedin'>Linkedin</a>
                </li>
              </ul>
            </div>

            <div className="offcanvas__links">
              <ul>
                <li>
                  <a href="/about/" aria-label='About'>About</a>
                </li>
                <li>
                  <a href="/contact/" aria-label='contact'>contact</a>
                </li>
                <li>
                  <a href="/career/" aria-label='Career'>Career</a>
                </li>
                <li>
                  <a href="/blog/" aria-label='blog'>blog</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="offcanvas__mid">
            <div className="offcanvas__menu-wrapper mean-container">
              <div className="mean-bar">
                <a
                  href="#nav"
                  className="meanmenu-reveal"
                  style={{ right: 0, left: "auto", display: "inline" }}
                  aria-label='navigation icon'
                >
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </a>
                <nav className="mean-nav">
                  <ul className="menu-anim" style={{ display: "none" }}>

                    {/* HIRE DEVELOPERS */}
                    {/* Services */}
                    <li>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingHeaderone">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseHeaderone"
                            aria-expanded="false"
                            aria-controls="collapseHeaderone"
                            onClick={toggle4}

                          >
                            <a>
                              <div className="menu-text">
                                <span>HIRE DEVELOPERS</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen4 ? "-" : "+"}
                            </a>
                          </button>
                        </h4>

                        <div
                          id="collapseHeaderone"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingHeaderone"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            {/* what we do/services */}
                            <ul>

                              <li>
                                <a href="/services/hire-web-developers" className=" relative z-10 hover:text-[#c9f31d]" aria-label='Hire Website Developers'>
                                  Hire Website Developers
                                </a>
                              </li>

                              <li className="whitespace-nowrap">
                                <a href="/services/web/hire-php-web-developers/" className="block" aria-label='Hire PHP Developers'>Hire PHP Developers</a>
                              </li>
                              <li className="whitespace-nowrap">
                                <a href="/services/web/hire-laravel-web-developers/" className="block" aria-label='Hire Laravel Developers'>Hire Laravel Developers</a>
                              </li>
                              <li className="whitespace-nowrap">
                                <a href="/services/web/hire-nodejs-developers/" className="block" aria-label='Hire NodeJS Developers'>Hire NodeJS Developers</a>
                              </li>
                              <li className="whitespace-nowrap">
                                <a href="/" className="block" aria-label='Hire ExpressJS Developers'>Hire ExpressJS Developers</a>
                              </li>

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block "> */}
                                <a href="/services/hire-frontend-developers/" className=" relative z-10 hover:text-[#c9f31d]" aria-label='Hire Frontend Developers'>
                                  {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                  Hire Frontend Developers
                                </a>
                                {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="/services/web/hire-reactjs-developers/" className="whitespace-nowrap" aria-label='Hire ReactJS Developers'>Hire ReactJS Developers</a>
                              </li>
                              <li>
                                <a href="/" className="whitespace-nowrap" aria-label='Hire Angular Developers'>Hire Angular Developers</a>
                              </li>

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                <a href="/" className=" relative z-10 hover:text-[#c9f31d]" aria-label='Hire Mobile Developers'>
                                  {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                  Hire Mobile Developers
                                </a>
                                {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="/services/mobile/hire-ios-developers/" aria-label='Hire iOS Developers'>Hire iOS Developers</a>
                              </li>
                              <li>
                                <a href="/services/mobile/hire-android-app-developers/" aria-label='Hire Android Developers'>Hire Android Developers</a>
                              </li>
                              <li>
                                <a href="/" aria-label='Hire Flutter Developers'>Hire Flutter Developers</a>
                              </li>
                              <li>
                                <a href="/" aria-label='Hire Swift Developers'>Hire Swift Developers</a>
                              </li>

                              {/* <hr /> */}

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                <a href="/" className=" relative z-10 hover:text-[#c9f31d]" aria-label='Hire Open Source Developers'>
                                  {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                  Hire Open Source Developers
                                </a>
                                {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="/services/web/hire-wordpress-developers/" className="whitespace-nowrap" aria-label='Hire Wordpress Developers'>Hire Wordpress Developers</a>
                              </li>
                              <li>
                                <a href="/services/web/hire-shopify-developers/" className="whitespace-nowrap" aria-label='Hire Shopify Developers'>Hire Shopify Developers</a>
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Solution */}
                    <li>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingEleven">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            onClick={toggle1}
                          >
                            <a>
                              <div className="menu-text">
                                <span>SOLUTIONS</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }} >
                              {isOpen1 ? "-" : "+"}
                            </a>
                          </button>
                        </h4>
                        <div
                          id="collapseEleven"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingEleven"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            <ul>
                              <li >
                                <a href="/solutions/" aria-label='Inventory and Warehouse'>Inventory & Warehouse</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Financial Accounting'>Financial Accounting</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Vehicle Tracking System'>Vehicle Tracking System</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Transport Management Solutions'>Transport Management Solutions</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='HR Management System (HRMS)'>HR Management System (HRMS)</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Smart Station Management'>Smart Station Management</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Customer Relationship Management (CRM)'>Customer Relationship Management (CRM)</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Smart Estate Management'>Smart Estate Management</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Procurement Management System'>Procurement Management System</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Real Estate and Construction'>Real Estate & Construction</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Sales and Invoice Processing'>Sales & Invoice Processing</a>
                              </li>
                              <li >
                                <a href="/solutions/" aria-label='Visitor Management System'>Visitor Management System</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Services */}
                    <li>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingTwelve">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            onClick={toggle2}

                          >
                            <a>
                              <div className="menu-text">
                                <span>SERVICES</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen2 ? "-" : "+"}
                            </a>
                          </button>
                        </h4>

                        <div
                          id="collapseTwelve"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwelve"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            {/* what we do/services */}
                            <ul>
                              {/* <div className="menu-heading  border-t border-b border-white"> */}
                              {/* <div className="relative group inline-block"> */}
                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                <a href="/what-we-do/" className=" relative z-10 hover:text-[#c9f31d]" aria-label='What We Do'>
                                  {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                  What We Do
                                </a>
                                {/* </div>
                                </div> */}
                              </li>
                              {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                              {/* </div> */}
                              {/* </div> */}

                              <li>
                                <a href="/what-we-do/web-development/" aria-label='Web Development'>Web Development</a>
                              </li>
                              <li>
                                <a href="/what-we-do/mobile-app-development/" aria-label='Mobile App Development'>Mobile App Development</a>
                              </li>
                              <li>
                                <a href="/what-we-do/design/" aria-label='UI/UX Designs'>UI/UX Designs</a>
                              </li>
                              <li>
                                <a href="/what-we-do/ai-ml-solutions/" aria-label='AI and ML App Development'>
                                  AI & ML App Development
                                </a>
                              </li>
                              <li>
                                <a href="/services/digital-marketing-services/" aria-label='Digital Marketing'>
                                  Digital Marketing
                                </a>
                              </li>
                              <li>
                                <a href="/services/hire-dedicated-developers/" aria-label='Hire Dedicated Developers'>
                                  Hire Dedicated
                                  Developers
                                </a>
                              </li>
                              {/* </ul> */}

                            </ul>

                          </div>
                        </div>
                      </div>
                    </li>

                    {/* OUR WORK */}
                    <li>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingThirteen">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                            onClick={toggle3}
                          >
                            <a>
                              <div className="menu-text">
                                <span>OUR WORK</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen3 ? "-" : "+"}
                            </a>
                          </button>
                        </h4>
                        <div
                          id="collapseThirteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThirteen"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            <ul className='items-center justify-center text-center'>

                              <ul>
                                <li>
                                  <a href="/case-studies/" aria-label='Case Studies'>
                                    <div className="relative group inline-block">
                                      Case Studies
                                      {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/portfolio/" aria-label='Our Work'>
                                    <div className="relative group inline-block">
                                      Our Work
                                      {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/" aria-label='Products'>
                                    <div className="relative group inline-block">
                                      Products
                                      {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                                    </div>
                                  </a>
                                </li>
                              </ul>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Company */}
                    <li>
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingFourteen">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                            onClick={toggle5}
                          >
                            <a>
                              <div className="menu-text">
                                <span>COMPANY</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen5 ? "-" : "+"}
                            </a>
                          </button>
                        </h4>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFourteen"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            <ul className='items-center justify-center text-center'>

                              <ul>
                                <li>
                                  <a href="/about/" aria-label='About Us'>
                                    <div className="relative group inline-block">
                                      About Us
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/how-to-engage/" aria-label='How We Work'>
                                    <div className="relative group inline-block">
                                      How We Work
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/career/" aria-label='Career'>
                                    <div className="relative group inline-block">
                                      Career
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/methodologies/" aria-label='Methodology'>
                                    <div className="relative group inline-block">
                                      Methodology
                                    </div>
                                  </a>
                                </li>

                                <li>
                                  <a href="/faqs/" aria-label='faqs'>
                                    <div className="relative group inline-block">
                                      faqs
                                    </div>
                                  </a>
                                </li>

                                <li>
                                  <a href="/blog/" aria-label='Blogs'>
                                    <div className="relative group inline-block">
                                      Blogs
                                    </div>
                                  </a>
                                </li>

                                <li>
                                  <a href="/faqs/" aria-label='faqs'>
                                    <div className="relative group inline-block">
                                      Testimonials
                                    </div>
                                  </a>
                                </li>

                              </ul>

                            </ul>
                          </div>
                        </div>

                      </div>
                    </li>

                    <li >
                      <a href="/contact/" aria-label='Lets Talk'>Let's Talk</a>
                    </li>


                  </ul>
                </nav>
              </div>

              <div className="mean-push" />

            </div>
          </div>


          <div className="offcanvas__right">
            <div className="offcanvas__search">

            </div>
            <div className="offcanvas__contact">
              <h3>Get in touch</h3>
              <ul>
                <li>
                  <a href="tel:+919909918338">(+91) 990 - 991 - 8338</a>
                </li>
                <li>
                  <a href="mailto:contact@jbcodeapp.com">contact@jbcodeapp.com</a>
                </li>
                <li>536-537, 5 Square Point, M.V Circle, JahangirPura, Surat, Gujarat 395005</li>
              </ul>
            </div>
            <img src="/assets/imgs/shape/11.png" alt="shape" className="shape-1" />
            <img src="/assets/imgs/shape/12.png" alt="shape" className="shape-2" />
          </div>
          <div className="offcanvas__close">
            <button type="button" id="close_offcanvas">
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
      </div >
      {/* Offcanvas area end */}
    </>
  )
}
