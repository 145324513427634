import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/components/Header/Header2.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Kanit\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"kanit\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/node_modules/select2/dist/css/select2.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/public/assets/css/all.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/public/assets/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/public/assets/css/swiper-bundle.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/public/assets/css/progressbar.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/public/assets/css/meanmenu.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jbcodeapp_nextjs/jbcodeapp_nextjs/public/assets/css/master.css");
